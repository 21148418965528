import { IconButton, MenuItem, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { t } from 'i18next';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import whiteLabelConfig from '../../../../config/whiteLabelConfig';

function SwitchApp() {
  const navigate = useNavigate();
  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} m={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4">{t('settings.settings')}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <IconButton>
            <SettingsIcon />
          </IconButton>
        </Stack>
      </Stack>
      <Stack direction="column" justifyContent="space-between" spacing={1} m={3}>
        {(import.meta.env.VITE_NODE_ENV === 'development' || import.meta.env.VITE_NODE_ENV === 'staging') && (
          <>
            {Object.entries(whiteLabelConfig)
              .filter(e => e)
              .map(([key, value]) => (
                <MenuItem
                  key={`drawer-menu-item-10` + key}
                  selected={localStorage.getItem('currentClient') === key}
                  onClick={() => {
                    localStorage.setItem('currentClient', key);
                    navigate('/');
                    window.location.reload();
                  }}
                  sx={{ height: 64 }}
                  disableRipple
                >
                  <img src={value.logo} width={48} />
                  <Typography variant="h6" fontWeight={600} ml={1} textAlign="left">
                    {' '}
                    {value.name}
                  </Typography>
                </MenuItem>
              ))}
          </>
        )}
      </Stack>
    </>
  );
}

export default SwitchApp;
