import { Avatar, Box, Button, Stack, Typography, colors } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
interface RewardCardProps {
  title: string;
  reward: string;
  cardImage: string;
}

const RewardCard = (props: RewardCardProps) => {
  const navigate = useNavigate();
  return (
    <Box onClick={() => navigate(`/pitch`)} mb={1} mt={1} p={2} height={200} width="100%" borderRadius={4}>
      <Stack direction="row" height="100%">
        <Stack direction="column" borderRadius={2} p={1} pr={2} justifyContent="center" alignItems="center">
          <img src={props.cardImage} alt="a" height="120%" style={{ borderRadius: 8 }} />
        </Stack>
        <Stack direction="column" justifyContent="space-between">
          <Stack direction="column">
            <Typography variant="xl" noWrap fontWeight={600}>
              {props.title}
            </Typography>
            <Stack direction="row" alignItems="center" py={1} ml={-0.5}>
              <img
                alt="a"
                src={props.cardImage == '1.png' ? '/images/rarities/gold.png' : '/images/rarities/diamond.png'}
                style={{ objectFit: 'contain' }}
                height={16}
              />
              <Typography variant="lg" ml={0.5} noWrap fontWeight={800} className="text-gradient-blue">
                {props.cardImage == '1.png' ? 'Gold editon' : 'Diamond édition'}
              </Typography>
            </Stack>
            <Typography variant="md" fontWeight={700} mt={1}>
              {props.reward}
            </Typography>
          </Stack>
          <Button
            variant="outlined"
            className="dark:text-white"
            startIcon={<QrCodeScannerIcon className="dark:text-white" />}
          >
            Réclamer
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default RewardCard;
